.chatList {
  width: 320px;
  height: 100%;
  border-right: 1px solid var(--color-border);
  overflow-x: auto;
}

.chatItem {
  height: 80px;
  position: relative;
  z-index: 1;
  padding: var(--size-m) var(--size-l) 0 var(--size-l);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-orange);
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    .bg {
      opacity: 0.08;
    }
  }
}

.chatItemContent {
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-bottom: var(--size-m);
  border-bottom: 1px solid var(--color-border);

  &.active {
    border-bottom: 1px solid var(--color-orange);
  }
}

.badge {
  display: inline-block;
  position: relative;
  top: -2px;
  min-width: 20px;
  padding: var(--size-xxs);
  font-size: var(--size-s);
  border-radius: var(--size-xxs);
  background: var(--color-pink-light);
  color: var(--color-red);
  text-align: center;
  text-transform: capitalize;

  &.new {
    display: none;
  }

  &.archived {
    background: var(--color-pink-light);
    color: var(--color-red);
  }

  &.resolved {
    background: var(--color-light-green);
    color: var(--color-green);
  }
}

.chatInfo {
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
  margin-left: var(--size-m);

  .header {
    height: var(--size-m);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--size-s);
  }

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .message {
    height: 30px;
    line-height: 26px;
    font-size: var(--size-s);

    padding-top: var(--size-xxs);

    .status {
      transform: translateY(4px);
      margin-right: var(--size-xxs);
      float: left;
      line-height: 14px;
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.filter {
  position: relative;
  height: 40px;
  padding: 0 var(--size-l);
  border-bottom: 1px solid var(--color-border);
  display: flex;
  align-items: center;
}

.loader {
  margin-top: var(--size-l);
}
