.container {
  width: 20px;
  height: 20px;
  position: relative;
  display: block;
  cursor: pointer;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  transition: all 0.3s ease;
  cursor: pointer;

  &.checked,
  &:hover {
    border: 1px solid var(--color-orange) !important;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    cursor: pointer;
  }
}
