:root {
  /* Colors */
  --color-orange: #fe7b01;
  --color-light-orange: #ff9e4f;
  --color-black: #1f1e1d;
  --color-text: var(--color-black);
  --color-gray: #6b6b6b;
  --color-gray-text: #b7b7b7;
  --color-white: #ffffff;
  --color-light-gray: #dedede;
  --color-lighter-gray: #f7f7f7;
  --color-green: #02c775;
  --color-light-green: #e5f9f0;
  --color-red: #ef4e4e;
  --color-pink-light: #fef1f1;
  --color-blue: #3772ff;
  --color-light-blue: #eff4ff;
  --color-yellow: #f7c137;

  /* Borders */
  --color-border: var(--color-light-gray);

  /* Input */
  --color-placeholder: #a5a5a5;

  /* Select */
  --color-select-options-background: var(--color-white);

  /* SideBar */
  --color-sidebar-background: var(--color-lighter-gray);
  --color-active-link-background: var(--color-light-gray);

  /* Size */
  --size-xxs: 4px;
  --size-xs: 8px;
  --size-s: 12px;
  --size-m: 16px;
  --size-ml: 20px;
  --size-l: 24px;
  --size-xl: 32px;
  --size-xxl: 40px;
  --size-xxxl: 48px;
  --size-xxxxl: 56px;
  --size-xxxxxl: 64px;

  /* Notifications */
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: var(--color-blue);
  --toastify-color-success: var(--color-green);
  --toastify-color-warning: var(--color-orange);
  --toastify-color-error: var(--color-red);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-color-progress-light: var(--toastify-color-light);
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--color-red);

  /* z-index */
  --z-index-overlay: 100;
}
