.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.table {
  max-width: 800px;
  height: 100%;
  overflow-y: auto;
}

.form {
  height: 100%;
  width: 600px;
}

.map {
  position: relative;
  flex: 1;
}

.drawMap {
  position: relative;
  flex: 1;
}

.infoWindowRow {
  display: flex;
  flex-direction: row;
  gap: var(--size-s);
  margin-bottom: var(--size-xs);
  cursor: default;

  svg {
    cursor: pointer;
  }

  p {
    flex: 1;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
