.container {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-xs);
  margin-right: var(--size-l);
  cursor: pointer;

  &.withBorder {
    border: 1px solid var(--color-orange);
    border-radius: var(--size-l);
    padding: var(--size-xs) var(--size-s);
  }

  p {
    font-size: var(--size-s);
    color: var(--color-orange);
    transition: all 0.3s ease;
  }

  svg,
  path {
    stroke: var(--color-orange);
    transition: all 0.3s ease;
  }

  &:hover {
    opacity: 0.5;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.2;

    p {
      color: var(--color-text);
    }

    svg path {
      stroke: var(--color-text);
    }
  }
}
