.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transform: skew(-10deg);
  border-radius: var(--size-xs);
  background: var(--color-white);
  border: 1px solid var(--color-border);
  transition: all 0.3s;
}

.sendButton {
  position: absolute;
  bottom: var(--size-xxs);
  right: var(--size-xxs);
  z-index: 2;
  width: 40px;
  height: 32px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.previews {
    border-radius: var(--size-xs);
    background: var(--color-white);
    border: 1px solid var(--color-border);
    padding: var(--size-xs);
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: var(--size-m);
  transform: translateY(-50%);
  z-index: 2;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  position: relative;
  z-index: 1;
  padding: 0 var(--size-l);
  font-size: var(--size-m);
  color: var(--color-text);
  outline: none;

  &.witIcon {
    padding-left: var(--size-xxl);
  }

  &.withSendButton {
    padding: 0 var(--size-xxxxl) 0 var(--size-m);
  }

  &::placeholder {
    color: var(--color-placeholder);
  }

  &:focus {
    + .background {
      border-color: var(--color-orange);
    }
  }
}

.previews {
  position: relative;
  z-index: 2;

  img {
    height: 80px;
    border-radius: var(--size-xxs);
    border: 1px solid var(--color-border);
  }
}

.inputWrapper {
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    min-height: inherit;
  }

  &.big {
    min-height: 56px;
  }

  &.small {
    min-height: 40px;

    .sendButton {
      transform: skewX(-10deg);
      right: var(--size-xs);

      & > * {
        transform: skew(10deg);
      }
    }

    .background {
      border-radius: var(--size-xxs);
    }
  }
}
