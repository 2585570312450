.container {
  width: 100%;
  height: 56px;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--color-orange);
  transition: all 0.3s ease;
  user-select: none;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  p {
    color: var(--color-white);
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
  }

  &.inclined {
    transform: skew(-10deg);

    & > * {
      transform: skew(10deg);
    }
  }

  &.small {
    width: auto;
    height: 32px;
    padding: var(--size-s) var(--size-xs);
    border-radius: var(--size-xxs);

    p {
      font-size: var(--size-s);
      font-style: normal;
    }
  }

  &.secondary {
    background: none;
    border: 1px solid var(--color-orange);

    p {
      color: var(--color-orange);
    }

    &.red {
      border-color: var(--color-red);

      p {
        color: var(--color-red);
      }

      svg path {
        stroke: var(--color-red);
      }
    }
  }

  &.rounded {
    border-radius: var(--size-m);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 1;
    background: var(--color-light-gray);

    p {
      color: var(--color-gray);
    }

    * {
      cursor: not-allowed;
    }

    &.secondary {
      background: inherit;
      opacity: 0.5;
    }
  }
}

.icon {
  margin-right: var(--size-xxs);
}
