.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 266px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-sidebar-background);
}

.navigation {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--size-xs);
  justify-content: flex-start;
  padding: var(--size-m);
  border-bottom: 1px solid var(--color-border);

  &.links {
    display: flex;
    flex: 1;
  }

  &:last-child {
    border-bottom: none;
  }
}

.link {
  display: flex;
  width: 100%;
  height: 27px;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--size-xs);
  gap: var(--size-xs);
  border: none;
  border-radius: var(--size-xs);
  background: none;
  font-size: var(--size-s);
  text-decoration: none;
  user-select: none;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }

  &.secondary {
    padding-left: var(--size-m);
  }

  &.active {
    background: var(--color-active-link-background);
  }
}

.content {
  flex: 1;
  padding-left: 266px;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  padding: var(--size-l) var(--size-l) var(--size-s);
  gap: var(--size-m);

  .account {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--size-m);
  }

  .user {
    display: flex;
    flex: 1;
    flex-direction: column;

    .name {
      font-size: var(--size-m);
      font-style: italic;
      font-weight: bold;
    }

    .position {
      margin-top: var(--size-xxs);
      font-size: var(--size-s);
    }
  }
}

.logout {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
