.rows {
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--size-l);

  & > div {
    width: 50%;
  }
}
