body * {
  box-sizing: border-box;
}

.Toastify__progress-bar {
  opacity: 1;
}

.gm-style > div:not(:first-child) {
  display: none;
}
