.container {
  width: 100%;
  text-align: center;
}

.loader {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: var(--color-orange);
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 2px;

  &:nth-child(2) {
    animation-delay: 0.5s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}
