.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  z-index: 2;
  border-radius: var(--size-xs);
  background: var(--color-white);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 1px 4px 3px 0px rgba(0, 0, 0, 0.5);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
  }

  &.active {
  }

  &.red {
    background: var(--color-red);

    path {
      stroke: var(--color-white);
    }
  }
}
