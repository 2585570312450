.container {
  padding: var(--size-l);
  border-bottom: 1px solid var(--color-border);
}

.content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-m);
}

h3 {
  margin-top: var(--size-m);
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: var(--size-xs);
}

.search {
  max-width: 333px;
  height: 40px;
}

.filter {
  display: flex;
  align-items: center;
  gap: var(--size-xs);
  margin-left: var(--size-l);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.4;
  }
}

.filters {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.stats {
  display: flex;
  flex-direction: column;
}

.line {
  display: flex;
  flex-direction: row;
}

.stat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding: 0 var(--size-m);
  border-left: 1px solid var(--color-border);
  transition: all 0.3s ease;

  &.clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.4;
    }
  }

  p {
    font-size: var(--size-s);
  }

  &:first-child {
    border-left: none;
  }

  &:before {
    content: '';
    display: inline-block;
    width: var(--size-xs);
    height: var(--size-xs);
    border-radius: 50%;
    margin-right: var(--size-xs);
  }

  &.green:before {
    background: var(--color-green);
  }

  &.red:before {
    background: var(--color-red);
  }

  &.blue:before {
    background: var(--color-blue);
  }

  &.black:before {
    background: var(--color-black);
  }

  &.orange:before {
    background: var(--color-orange);
  }

  &.gray:before {
    background: var(--color-gray);
  }

  &.yellow:before {
    background: var(--color-yellow);
  }
}

.actions {
  & > * {
    margin-top: var(--size-l);
  }
}

.rightSubTitle{
  margin-left: 32px;
  position: relative;
}

.rightSubTitle::before{
  content: '';
  position: absolute;
  display: block;
  width: 1px;
  height: 24px;
  left: -16px;
  top: 4px;
  background-color: #DEDEDE;
}