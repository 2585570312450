.container {
  width: 100%;
  height: 72px;
  padding: 0 var(--size-l);
  background: var(--color-white);
}

.content {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.connection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: var(--size-m);
  font-size: var(--size-s);
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--color-red);
  margin-right: var(--size-xxs);

  &.green {
    background: var(--color-green);
  }

  &.yellow {
    background: var(--color-yellow);
  }
}
