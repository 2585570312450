.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.container {
  flex: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
}

.tableWrapper {
  flex: 1;
  padding: 0 var(--size-l);
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
}

.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;

  th {
    position: sticky;
    top: 0px;
    background: var(--color-white);
    z-index: 2;
    user-select: none;
  }
}

.row {
  height: 43px;
  vertical-align: middle;
  border-bottom: 1px solid var(--color-border);
  transition: all 0.3s ease;

  &.clickable:hover {
    cursor: pointer;
    background: rgba(254, 123, 1, 0.08);
  }

  &.selected {
    border-bottom-color: var(--color-orange);
    background: rgba(254, 123, 1, 0.08);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.cell {
  padding: 0 var(--size-xs);
  vertical-align: middle;
  transition: all 0.3s ease;
  font-size: var(--size-s);
  text-align: left;

  &.center {
    text-align: center;
  }

  &.orange {
    color: var(--color-orange);
  }
}

.loader {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.pagination {
  padding: var(--size-l);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 20px;
  justify-content: center;
}

.total {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: var(--size-s);
  padding-right: var(--size-xs);
  color: var(--color-gray-text);
  border-right: 1px solid var(--color-border);
}

.pages {
  display: flex;
  flex-direction: row;
  padding: 0 var(--size-xs);
  gap: var(--size-xxs);
  border-right: 1px solid var(--color-border);
}

.control,
.item {
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: var(--size-s);
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: var(--size-xxs);
  transition: all 0.3s ease;

  &.active {
    background: var(--color-light-gray);
    color: var(--color-orange);
  }

  &:hover {
    opacity: 0.4;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &.dots {
    opacity: 1;
  }

  &.right {
    transform: scale(-1, 1);
  }

  &.loading {
    pointer-events: none;
    cursor: default;
  }
}

.sort {
  svg {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-left: 4px;
  }

  &.active {
    svg {
      transform: translateY(-1px);
    }
  }
}

.limits {
  height: 20px;
  display: flex;
  padding: 0 var(--size-xs);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--size-s);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot {
  width: var(--size-xs);
  height: var(--size-xs);
  background: var(--color-green);
  border-radius: 50%;
  display: inline-block;
  margin-right: var(--size-xs);

  &.noMargin {
    margin-right: 0;
  }

  &.red {
    background: var(--color-red);
  }

  &.yellow {
    background: var(--color-yellow);
  }

  &.black {
    background: var(--color-text);
  }

  &.blue {
    background: var(--color-blue);
  }

  &.orange {
    background: var(--color-orange);
  }
}
