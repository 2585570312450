.container {
  display: flex;
  height: 100vh;
  background: var(--color-white);
}

.background {
  width: 30%;
  height: 100%;
  background: no-repeat url('./assets/background.jpg');
  background-size: cover;
}

.form {
  flex: 1;
  height: 100%;
  padding-left: 106px;
  display: flex;
  align-items: center;
}

.content {
  width: 460px;

  h1 {
    margin-bottom: 40px;
  }
}

.input {
  margin-bottom: var(--size-m);
}

.button {
  margin-top: var(--size-xxxl);
}
