
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

.wrapper{
  padding: 24px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: var(--size-m);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
  width: 550px;
  margin-bottom: 16px;
  position: relative;
}

.actions {
  margin-left: 366px;
  height: 48px;
  margin-top: 24px;

  button {
    height: 100%;
    max-width: 180px;
  }
}

.datePicker{
  display: none;
}

.datePickerPoper{
  z-index: 1000000 !important;
  left: -20px !important;
  top: -20px !important;
}

.calendarIcon{
  position: absolute;
  right: 14px;
  top: 18px;
  cursor: pointer;
  display: block;
  z-index: 100000;
}

.preview{
  margin-left: 5%;
  flex: 1;

  h3{
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.previewBox{
  background-color: #F7F7F7;
  max-width: 416px;
  width: 100%;
  max-height: 240px;
  height: 240px;
  padding: 80px 30px;
  border-radius: 10px;
}

.previewItem{
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.previewText{
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.previewTitle{
  margin: 0 !important;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}