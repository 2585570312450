.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
}

.redColor {
  color: var(--color-red);
}

.orangeColor {
  color: var(--color-orange);
}

.changeScooterAction{
  color: var(--color-white);
    background-color: var(--color-orange);
    opacity: 0.9;
    border-radius: 10px;
    margin: 5px 0;
    width: 120px;
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
}
