.container {
  position: relative;
  width: 100%;
  height: 32px;
  z-index: 2;
  user-select: none;

  &.big {
    padding: 0 var(--size-l);
    height: 56px;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-border);
      border-radius: var(--size-xs);
      transform: skew(-10deg);
    }

    .arrow {
      width: 10px;
      height: 10px;
      right: 0px;
    }

    p {
      margin: 0;
      font-size: var(--size-m);
      line-height: 20px;
    }

    .options {
      margin-top: 5px;
    }

    .option {
      padding: 10px;
    }
  }

  &.bordered {
    border: 1px solid var(--color-border);
    border-radius: var(--size-xs);

    .options {
      margin-top: 5px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}

.input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-right: var(--size-l);

  p {
    display: block;
    width: 100%;
    margin-left: var(--size-xs);
    font-weight: 300;
    font-size: var(--size-s);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.arrow {
  position: absolute;
  width: 6px;
  height: 6px;
  border-right: 1.5px solid var(--color-orange);
  border-top: 1.5px solid var(--color-orange);
  top: 50%;
  right: 10px;
  transform: rotate(135deg) translateY(50%);
  margin-top: -2px;
  transition: all 0.3s ease;

  &.up {
    margin-top: 0px;
    transform: translateY(-50%) rotate(315deg);
  }
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: var(--size-xs);
  background: var(--color-white);
  z-index: 3;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  p {
    font-weight: 300;
    font-size: var(--size-s);
  }

  &.top {
    top: auto;
    bottom: 100%;
  }
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background: var(--color-light-gray);
  }

  &:hover {
    background: var(--color-orange);

    p {
      color: var(--color-white);
    }
  }
}
