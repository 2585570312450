.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.map {
  position: relative;
}

.mapContainer {
  position: relative;
}

.dot {
  width: var(--size-xs);
  height: var(--size-xs);
  background: var(--color-green);
  border-radius: 50%;
  display: inline-block;
  margin-right: var(--size-xs);

  &.noMargin {
    margin-right: 0;
  }

  &.red {
    background: var(--color-red);
  }

  &.black {
    background: var(--color-text);
  }

  &.blue {
    background: var(--color-blue);
  }

  &.orange {
    background: var(--color-orange);
  }
}

.redColor {
  color: var(--color-red);
}

.orangeColor {
  color: var(--color-orange);
}

.priority {
  display: inline-block;
  padding: var(--size-xxs) var(--size-xs);
  border-radius: var(--size-s);
  font-size: var(--size-s);

  &.high {
    background: var(--color-red);
    color: var(--color-white);
  }

  &.low {
    background: var(--color-green);
    color: var(--color-white);
  }
}

.container {
  display: flex;
  height: 100vh;
}

.test {
  background-color: #f0f0f0;
  transition: width 0.2s ease;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.sidePanel {
  background-color: #fff;
  cursor: ew-resize;
  user-select: none;
  position: absolute;
  top: 50%;
  left: -10px;
  width: 20px;
  height: 20px;
  color: #000;
  z-index: 100000;
  border-radius: 50px;
  border: 1px solid  var(--color-green);
  display: flex;
  justify-content: center;
  align-items: center;
}