.container {
  width: 80%;
  max-width: 730px;
  padding: var(--size-l);
  border-radius: var(--size-m);
  background-color: var(--color-white);
  animation: fadeInModal 0.5s ease;
}

.title {
  display: flex;
  align-items: center;
  height: 48px;
  flex-direction: row;
  margin-bottom: var(--size-xl);

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 46px;
    transform: skew(-10deg);
    border-radius: var(--size-xs);
    background: var(--color-lighter-gray);
    margin-right: var(--size-m);

    svg {
      transform: skew(10deg);

      path {
        stroke: var(--color-black);
      }
    }
  }
}

.content {
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: var(--size-s);
  height: 48px;
  margin-top: var(--size-xl);

  button {
    height: 100%;
    max-width: 160px;
  }
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
