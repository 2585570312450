.marker {
  position: relative;

  & > svg {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
}

.drawMarker {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: var(--color-orange);
  transition: all 0.3s ease-in-out;

  &.hovered {
    width: 8px;
    height: 8px;
    background-color: var(--color-black);
  }
}

.controls {
  position: absolute;
  left: var(--size-m);
  top: var(--size-m);
  z-index: 2;
  display: flex;
  flex-direction: row;
  gap: var(--size-s);
}

.scooterMarker {
  position: relative;
  z-index: 3;
  cursor: pointer;
  pointer-events: all;
}

.dotMarker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;

  &.active {
    width: 15px;
    height: 15px;
  }

  &.red {
    background-color: var(--color-red);
  }

  &.green {
    background-color: var(--color-green);
  }
}

.infoWindow {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  font-size: var(--size-s);
  transform: translate(-50%, 4px);
  padding: var(--size-xxs) var(--size-xs);
  border-radius: var(--size-xxs);
  white-space: nowrap;
  background: var(--color-white);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05),
    0px 7px 5px 0px rgba(0, 0, 0, 0.01);
}
