.container {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--size-s);
  background-color: var(--color-white);
  z-index: 100;
  transform: translateX(100%);
  box-shadow: -118px 0px 47px rgba(0, 0, 0, 0.01),
    -66px 0px 40px rgba(0, 0, 0, 0.05), -29px 0px 29px rgba(0, 0, 0, 0.09),
    -7px 0px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &.active {
    transform: translateX(0);
    z-index: 100000000;
  }
}

.closeButton {
  transition: all 0.3s ease-in-out;

  &.active {
    transform: rotate(180deg);
  }
}

.content {
  padding: var(--size-s);
}

.title {
  display: flex;
  align-items: center;
  margin-top: var(--size-m);

  .titleBadge {
    font-size: var(--size-s);
    padding: var(--size-xxs) var(--size-xs);
    border-radius: var(--size-ml);
    color: var(--color-white);
    font-weight: 300;
    font-style: normal;

    &:first-child {
      margin-left: var(--size-xs);
    }

    &.red {
      background: var(--color-red);
    }

    &.green {
      background: var(--color-green);
    }

    &.blue {
      color: var(--color-blue);
      background: var(--color-light-blue);
    }
  }

  .divider {
    height: 10px;
    width: 1px;
    background: var(--color-border);
    margin: 0 var(--size-m);
  }
}

.tableContainer {
  width: 100%;
  margin-top: var(--size-xxxl);
}

.tableTitleContainer {
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: var(--size-m);

  .icon {
    transform: translateY(-1px);
    margin-right: var(--size-xs);
  }
}

.tableTitle {
  margin: 0;
  font-size: var(--size-m);

  &.red {
    color: var(--color-red);
  }
}

.table {
  margin: 0 -24px;
}
