.container {
  position: relative;
  height: 100%;
}

.expandButton {
  position: absolute;
  top: var(--size-m);
  left: var(--size-l);
}

.title {
  position: absolute;
  top: var(--size-m);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  font-size: var(--size-m);
  padding: var(--size-xs) var(--size-s);
  background: var(--color-white);
  border-radius: var(--size-xs);
  user-select: none;
}

.infoWindow {
  position: absolute;
  min-width: 200px;
  top: var(--size-m);
  right: var(--size-m);
  z-index: 2;
  background: var(--color-white);
  padding: var(--size-s);
  border-radius: var(--size-xs);
  box-shadow: 1px 4px 3px 0px rgba(0, 0, 0, 0.16);

  p {
    font-size: var(--size-s);
  }
}
