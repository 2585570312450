.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.messages {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  padding: var(--size-xxxxl) var(--size-l) 0;
  overflow-y: auto;
}

.input {
  display: flex;
  gap: var(--size-s);
  padding: var(--size-s);
}

.message {
  position: relative;
  max-width: 70%;
  min-width: 120px;
  margin-bottom: var(--size-xs);

  .date {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: var(--size-xs);
    font-size: var(--size-s);
    color: var(--color-light-gray);
  }

  .avatar {
    position: absolute;
    bottom: 100%;
    left: 0;
    transform: translate(-5px, 15px);
  }

  &.first {
  }

  &.last {
    margin-bottom: var(--size-xl);
  }

  &.me {
    margin-right: auto;

    .textWrapper {
      background: var(--color-lighter-gray);
    }
  }

  &.other {
    margin-left: auto;

    .textWrapper {
      background: var(--color-white);
      border-color: var(--color-black);
    }

    .avatar {
      right: 0;
      left: auto;
      transform: translate(5px, 15px);
    }

    .date {
      left: 0;
      right: auto;
    }
  }
}

.textWrapper {
  padding: var(--size-s) var(--size-m);
  border-radius: var(--size-xs);
  border: 1px solid var(--color-lighter-gray);
}

.loader {
  margin-top: var(--size-xxl);
}

.files {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-s);
  flex-wrap: nowrap;

  &.filesWithText {
    flex-wrap: wrap;
    margin-bottom: var(--size-xs);
  }
}

.file {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.fileIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--size-xs);
  background: var(--color-lighter-gray);
}

.image {
  max-width: 100px;
  height: auto;
  border-radius: var(--size-xs);
  aspect-ratio: attr(width) / attr(height);
}

.addFile {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: flex-end;
  padding-bottom: var(--size-s);
  gap: var(--size-xs);
  cursor: pointer;
  user-select: none;

  .text {
    font-size: var(--size-s);
    color: var(--color-orange);
  }
}
