h1,
h2,
h3,
h4,
p {
  color: var(--color-text);
  font-family: Arial, Helvetica, sans-serif;
}

th,
td {
  color: var(--color-text);
  font-size: var(--size-s);
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  color: var(--color-text);
  font-size: var(--size-xl);
  font-style: italic;
  font-weight: bold;
}

h2 {
  color: var(--color-text);
  font-size: var(--size-l);
  font-style: italic;
  font-weight: bold;
}

h3 {
  color: var(--color-text);
  font-size: var(--size-ml);
  font-style: italic;
  font-weight: bold;
}
