.map {
  width: 100%;
  height: 150px;
  margin-top: var(--size-l);
  border-radius: var(--size-s);
  overflow: hidden;
}

.actionContainer {
  margin-top: var(--size-l);
}

.action {
  margin-top: var(--size-xs);
  margin-right: var(--size-xs);
}
